import React from 'react';
import '../styles/footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <p>&copy; Mattress Bank Inc. {new Date().getFullYear()}</p>
            </div>
        </footer>
    );
};

export default Footer;
