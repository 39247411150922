import React, { useState } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Button,
    Spinner,
} from 'reactstrap';

import '../styles/settings.css';
import Header from '../components/header.tsx';
import Sidebar from '../components/sidebar.tsx';
import Footer from '../components/footer.tsx';
import UserService from '../api/UserService.tsx';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/auth.slice.ts';
import { Helmet } from 'react-helmet-async';

interface BankingSettingsProps { }

const BankingSettings: React.FC<BankingSettingsProps> = () => {
    const [previousPassword, setPreviousPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { user } = useSelector(selectAuth);
    const { userId } = user;


    const handlePasswordChange = async () => {
        try {
            if (!previousPassword || !newPassword || !confirmPassword) {
                setError('Please fill out all fields');
                toast.error('Please fill out all fields');
                return;
            }
            setLoading(true);
            if (!isPasswordValid(newPassword)) {
                setError('Password must be at least 8 characters long, contain a number, an uppercase letter, a lowercase letter and a special character');
                toast.error('Password must be at least 8 characters long, contain a number, an uppercase letter, a lowercase letter and a special character');
                return;
            }
            if (!isPasswordMatching(newPassword, confirmPassword)) {
                setError('Passwords do not match');
                toast.error('Passwords do not match');
                return;
            }
            await UserService.changePassword({ userId, oldPassword: previousPassword, newPassword });
            toast.success('Password changed successfully');
            setPreviousPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setError('');
        } catch (error) {
            setError(error.message);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const isPasswordValid = (password: string) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
        return passwordRegex.test(password);
    };

    const isPasswordMatching = (password: string, confirmPassword: string) => {
        return password === confirmPassword;
    }

    return (
        <>
            <Helmet>
                <title>Settings - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <Header />
            <Sidebar />
            <div className="settings-container">
                <Card className="custom-card">
                    <CardBody>
                        <CardTitle className=" mb-3 text-center" tag="h3">
                            Change Password
                        </CardTitle>
                        <Form className='formSettings'>
                            <FormGroup>
                                <Input
                                    type="password"
                                    name="previousPassword"
                                    placeholder="Enter previous password"
                                    value={previousPassword}
                                    onChange={(e) => setPreviousPassword(e.target.value)}
                                    className='settingsformInput'
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="password"
                                    name="newPassword"
                                    placeholder="Enter new password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className='settingsformInput'
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirm new password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className='settingsformInput'
                                    required
                                />
                            </FormGroup>
                            {error && <p className='error'>{error}</p>}
                            {loading ? (<div className='spinner'>
                                <Spinner
                                    color="primary"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="secondary"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="success"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="danger"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="warning"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="info"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="light"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="dark"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                            </div>) : (
                                <>
                                    <Button
                                        onClick={handlePasswordChange}
                                        className='settingsButtonInput'
                                        style={{ backgroundColor: "#27ca9f" }}
                                    >
                                        Change Password
                                    </Button>
                                </>)}
                        </Form>
                    </CardBody>
                </Card>
                <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            </div>
            <Footer />
        </>
    );
};

export default BankingSettings;
