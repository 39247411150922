import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './pages/SignIn.tsx';
import Signup from './pages/SignUp.tsx';
import OTPPage from './pages/Otp.tsx';
import ForgotPassword from './pages/ForgotPassword.tsx';
import Home from './pages/Home.tsx';
import ProfilePage from './pages/ProfilePage.tsx';
import OnboardingPage from './pages/Onboarding.tsx';
import MoneyTransfer from './pages/Transfer.tsx';
import TransactionHistory from './pages/Transactions.tsx';
import Beneficiaries from './pages/Beneficiaries.tsx';
import HelpMe from './pages/Help.tsx';
import AccountVerificationPage from './pages/AccountVerification.tsx';
import PrivateRoute from './auth/privateRoute.tsx';
import BankingSettings from './pages/Settings.tsx';
import SuccessPage from './pages/SuccessfulTransfer.tsx';
import { HelmetProvider } from 'react-helmet-async';
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth, logout } from './redux/slices/auth.slice.ts';
import GoogleOauth from './auth/googleAuth.js';
import GoogleSignUp from './pages/GoogleSignUp.jsx';
import GoogleLogin from './pages/GoogleLogin.jsx';
import NotFoundPage from './pages/NotFound.tsx';

const App = () => {
  const [state, setState] = useState('Active')
  const [count, setCount] = useState(0)
  const [remaining, setRemaining] = useState(0)
  const { isAuthenticated } = useSelector(selectAuth);
  const helmetContext = {};
  const dispatch = useDispatch();


  const onIdle = () => {
    setState('Idle')
  }

  const onActive = () => {
    setState('Active')
  }

  const onAction = () => {
    setCount(count + 1)
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 180000,
    throttle: 500
  })



  useEffect(() => {

    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    if (remaining <= 90 && isAuthenticated) {
      window.alert('Your session will expire soon due to inactivity!');
    }

    if (remaining <= 0 && isAuthenticated) {
      window.alert('You have been logged out due to inactivity.');
      dispatch(logout())
      window.location.href = '/signin';
    }

    return () => {
      clearInterval(interval);
    }
  }, [dispatch, getRemainingTime, isAuthenticated, remaining, state])


  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        <Fragment>
          <div className="app">
            <Routes>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/verify-account" element={<AccountVerificationPage />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/otp" element={<OTPPage />} />
              <Route path="/onboarding" element={<OnboardingPage />} />
              <Route path="/google/oauth" element={<GoogleOauth />} />
              <Route path="/google/login" element={<GoogleLogin />} />
              <Route path="/google/register" element={<GoogleSignUp />} />
              <Route element={<PrivateRoute />}>
                <Route path="/" element={<Home />} />
                <Route path="/money-transfer" element={<MoneyTransfer />} />
                <Route path="/transactions" element={<TransactionHistory />} />
                <Route path="/beneficiaries" element={<Beneficiaries />} />
                <Route path="/help" element={<HelpMe />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="/settings" element={<BankingSettings />} />
                <Route path="/success" element={<SuccessPage />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </Fragment>
      </Router>
    </HelmetProvider>
  );
};

export default App;
