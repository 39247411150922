import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false,
    user: {
        email: '',
        sessionToken: '',
        userId: '',
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            const { email, sessionToken, userId } = action.payload;
            state.isAuthenticated = true;
            state.user = { email, sessionToken, userId };
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = {
                email: '',
                sessionToken: '',
                userId: '',
            };
        },
    },
});

export const { login, logout } = authSlice.actions;
export const selectAuth = (state: { auth: any }) => state.auth;
export default authSlice.reducer;
