import { configureStore } from '@reduxjs/toolkit';
import authReducer from './redux/slices/auth.slice.ts';
import profileReducer from './redux/slices/profile.slice.ts';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { thunk } from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedProfileReducer = persistReducer(persistConfig, profileReducer);

const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        profile: persistedProfileReducer,
    },
    middleware: [thunk],
});

const persistor = persistStore(store);

export { store, persistor };
