import React, { useEffect, useState } from 'react';
import { FaExchangeAlt, FaUserFriends, FaCog, FaQuestionCircle, FaBars, FaUserCog, FaMoneyBillWave, FaHome } from 'react-icons/fa';
import '../styles/sidebar.css';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const thresholdWidth = 768;

            if (screenWidth <= thresholdWidth) {
                setCollapsed(true);
            } else {
                setCollapsed(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isPageActive = (path) => {
        // Check if the current location path matches the provided path
        return location.pathname === path;
    };

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
            <div className='space'></div>
            <button className="toggle-btn" onClick={toggleSidebar}>
                <FaBars />
            </button>
            {!collapsed && (
                <>
                    <div className='space'></div>
                    <div className={`sidebar-item ${isPageActive('/') ? 'active' : ''}`}>
                        <FaHome className="icon" />
                        <Link to="/" className='link'>
                            Overview
                        </Link>
                    </div>
                    <div className={`sidebar-item ${isPageActive('/money-transfer') ? 'active' : ''}`}>
                        <FaMoneyBillWave className="icon" />
                        <Link to="/money-transfer" className='link'>
                            Transfer Funds
                        </Link>
                    </div>
                    <div className={`sidebar-item ${isPageActive('/transactions') ? 'active' : ''}`}>
                        <FaExchangeAlt className="icon" />
                        <Link to="/transactions" className='link'>
                            Transactions
                        </Link>
                    </div>
                    <div className={`sidebar-item ${isPageActive('/beneficiaries') ? 'active' : ''}`}>
                        <FaUserFriends className="icon" />
                        <Link to="/beneficiaries" className='link'>Beneficiaries</Link>
                    </div>
                    <div className={`sidebar-item ${isPageActive('/settings') ? 'active' : ''}`}>
                        <FaCog className="icon" />
                        <Link to="/settings" className='link'>Settings</Link>
                    </div>
                    <div className={`sidebar-item ${isPageActive('/help') ? 'active' : ''}`}>
                        <FaQuestionCircle className="icon" />
                        <Link to="/help" className='link'>Help</Link>
                    </div>
                    <div className={`sidebar-item ${isPageActive('/signin') ? 'active' : ''}`}>
                        <FaUserCog className="icon" />
                        <Link to="/signin" className='link'>Logout</Link>
                    </div>
                </>
            )}
        </div>
    );
};

export default Sidebar;
