import React from 'react';
import { Card, CardBody } from 'reactstrap';
import '../styles/404.css';
import Footer from '../components/footer.tsx';
import Header from '../components/header.tsx';
import Sidebar from '../components/sidebar.tsx';
import { useSelector } from 'react-redux';

const NotFoundPage = () => {
    const { isAuthenticated } = useSelector((state: any) => state.auth);

    return (
        <>
            {isAuthenticated && (
                <>
                    <Header />
                    <Sidebar />
                </>
            )}
            <div className="notFoundContainer">
                <div className='container'>
                    <Card className='card'>
                        <CardBody>
                            <h2 className='notFoundHeader'>404 - Not Found</h2>
                            <p className='notFoundParagraph'>Sorry, the page you are looking for does not exist.</p>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default NotFoundPage;
