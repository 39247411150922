import React, { useEffect, useState } from 'react';
import {
    Card, CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Progress,
    Spinner,

} from 'reactstrap';
import Header from '../components/header.tsx';
import Footer from '../components/footer.tsx';
import '../styles/transfer.css';
import TextArea from 'antd/es/input/TextArea';
import Sidebar from '../components/sidebar.tsx';
import AccountsService from '../api/AccountsService.tsx';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/auth.slice.ts';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const MoneyTransfer = () => {
    const { user } = useSelector(selectAuth);
    const profile = useSelector((state: { profile: any; }) => state.profile);
    const { userId } = user;
    const [currentStep, setCurrentStep] = useState(1);
    const [suggestedAccounts, setSuggestedAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accountBalance, setAccountBalance] = useState(0);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [transferAmount, setTransferAmount] = useState('');
    const [transferReason, setTransferReason] = useState('');
    const [error, setError] = useState('');
    const [sourceAccount, setSourceAccount] = useState('');
    const { accountNumber } = profile;
    const navigate = useNavigate();

    const currency = 'USD'

    const getSuggestions = async (inputValue) => {
        try {
            setLoading(true);
            const data = await AccountsService.getAllAccountNumbers();
            const accountNumbers = data?.data;
            const availableAccounts = accountNumbers.map(
                (account) => account.accountNumber
            );
            const filteredAccounts = availableAccounts.filter((account) =>
                account.includes(inputValue)
            );
            setSuggestedAccounts(filteredAccounts.slice(0, 3));
        } catch (error) {
            setError(error.message);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const getAccountBalance = async () => {
            try {
                setLoading(true);
                const response = await AccountsService.getUserAccountNumber(userId);
                const balance = response?.data?.balance;
                setAccountBalance(balance);
            } catch (error) {
                setError(error.message);
                toast.error('An Error occurred while fetching your account balance, please try again later');
            } finally {
                setLoading(false);
            }

        }

        setSourceAccount(accountNumber);
        getAccountBalance();
    }, [accountNumber, userId]);


    const handleNext = () => {
        if (currentStep < 3) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrev = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        getSuggestions(inputValue);
        setSelectedAccount(inputValue);
    };

    const handleAccountSelect = (selectedAccount: React.SetStateAction<string>) => {
        setSelectedAccount(selectedAccount);
        setSuggestedAccounts([]);
    };

    const handleTransfer = async () => {
        try {
            setLoading(true);
            const payload = {
                amount: Number(transferAmount),
                description: transferReason,
                sourceAccountNumber: sourceAccount,
                destinationAccountNumber: selectedAccount,
            }
            const response = await AccountsService.sendMoney(payload);
            toast.success('Transaction Successful');
            navigate('/success', {
                state: {
                    selectedAccount,
                    transferAmount,
                    referenceNumber: response?.transaction?.referenceNumber,
                    newBalance: response?.transaction.newBalance,
                },
            });
        } catch (error) {
            setError(error.message);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        switch (currentStep) {
            case 1:
                if (selectedAccount === accountNumber) {
                    setError('You cannot transfer funds to the same account');
                    return;
                }
                setError('');
                break;
            case 2:
                setError('');
                break;
            case 3:
                handleTransfer();
                break;
            default:
                break;
        }

        handleNext();
    };

    const calculateCompletionPercentage = () => {
        const totalSteps = 3;
        return (currentStep / totalSteps) * 100;
    };

    const formatCurrency = (amount: any) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency || 'USD',
            minimumFractionDigits: 2,
        }).format(amount);
    };





    return (
        <>
            <Helmet>
                <title>Transfer Funds - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <Header />
            <Sidebar />
            <div className="money-transfer-container">
                <Card className="money-transfer-card">
                    <CardBody>
                        <h4 className="heading"><u>Transfer Funds</u></h4>
                        {/* <h3 className="heading"><u>Step {currentStep}/3</u></h3> */}
                        <br />
                        <Progress value={calculateCompletionPercentage()} striped color='success' />
                        <br />
                        <Form onSubmit={handleSubmit}>
                            {currentStep === 1 && (
                                <FormGroup>
                                    <Label for="selectedAccount">Type Account Number to Begin</Label>
                                    <Input
                                        type="text"
                                        id="selectedAccount"
                                        name="selectedAccount"
                                        value={selectedAccount}
                                        placeholder="Type to search accounts"
                                        onChange={handleChange}
                                        style={{ width: '100%' }}
                                        required
                                        className='formTransferInput'
                                    />
                                    {suggestedAccounts.length > 0 && (
                                        <div className="suggestion-container">
                                            {suggestedAccounts.map((account, index) => (
                                                <Card key={index} className="suggestion-card" onClick={() => handleAccountSelect(account)}>
                                                    <CardBody>
                                                        {account}
                                                    </CardBody>
                                                </Card>
                                            ))}
                                        </div>
                                    )}
                                </FormGroup>
                            )}
                            {currentStep === 2 && (
                                <>
                                    <FormGroup>
                                        <Label for="transferAmount">Transfer Amount</Label>
                                        <Input
                                            type="number"
                                            id="transferAmount"
                                            name="transferAmount"
                                            value={transferAmount}
                                            placeholder="Enter transfer amount"
                                            min={2}
                                            onChange={(e) => setTransferAmount(e.target.value)}
                                            required
                                            style={{ width: '100%' }}
                                            className='formTransferInput'
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="transferReason">Transfer Reason</Label>
                                        <TextArea
                                            id="transferReason"
                                            name="transferReason"
                                            value={transferReason}
                                            placeholder="Enter reason for transfer"
                                            onChange={(e) => setTransferReason(e.target.value)}
                                            required
                                            size='large'
                                            style={{ width: '100%' }}
                                            className='formTransferInput'
                                        />
                                    </FormGroup>
                                </>
                            )}
                            {currentStep === 3 && (
                                <div className="review-confirm-container">
                                    <h5>Review and Confirm:</h5>
                                    <div className="review-details">
                                        <p><strong>Transfer From:</strong> {accountNumber}</p>
                                        <p><strong>Transfer To:</strong> {selectedAccount}</p>
                                        <p><strong>Amount:</strong> {formatCurrency(transferAmount)}</p>
                                        <p><strong>Charges:</strong> {formatCurrency(10.00)}</p>
                                        <p><strong>Taxes:</strong> {formatCurrency(5.00)}</p>
                                        <p><strong>Total Amount:</strong> {formatCurrency(parseFloat(transferAmount) + 10.00 + 5.00)}</p>
                                        <p><strong>Reason:</strong> {transferReason}</p>
                                        <p><strong>Date:</strong> {new Date().toLocaleDateString()}</p>
                                        <p><strong>Account Balance After Transfer:</strong> {formatCurrency(accountBalance - (parseFloat(transferAmount) + 10.00 + 5.00))}</p>
                                    </div>
                                </div>


                            )}
                            {error && <div className='errorTransfer'>{error}</div>}
                            {loading ? (<div className='spinner'>
                                <Spinner
                                    color="primary"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="secondary"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="success"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="danger"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="warning"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="info"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="light"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="dark"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                            </div>) : (
                                <div className="button-container">
                                    {currentStep > 1 && (
                                        <Button onClick={handlePrev} className="transferButtonSubmit">
                                            Previous
                                        </Button>
                                    )}
                                    <Button type="submit" className="transferButtonSubmit" style={{ backgroundColor: '#27ca9f' }}>
                                        {currentStep === 3 ? 'Confirm Transfer' : 'Next'}
                                    </Button>
                                </div>
                            )}

                        </Form>
                    </CardBody>
                </Card>
                <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            </div>
            <Footer />
        </>
    );
};

export default MoneyTransfer;
