import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userName: '',
    lastName: '',
    firstName: '',
    email: '',
    phoneNumber: '',
    address: '',
    resident_country: '',
    dateOfBirth: '',
    nationalID: '',
    gender: '',
    accountBalance: 0,
    accountNumber: '',
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            return { ...state, ...action.payload };
        },
        updateBalance: (state, action) => {
            state.accountBalance = action.payload;
        },
    },
});

export const { setProfile, updateBalance } = profileSlice.actions;
export const selectProfile = (state: { profile: any }) => state.profile;
export default profileSlice.reducer;
