import React, { useEffect, useState } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import logo from '../images/logo.svg';
import { FaBars, FaCog, FaEnvelope, FaExchangeAlt, FaMoneyBillWave, FaQuestionCircle, FaUser, FaUserCog, FaUserFriends } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import '../styles/header.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/auth.slice.ts';
import { setProfile } from '../redux/slices/profile.slice.ts';
import { toast } from 'react-toastify';
import UserService from '../api/UserService.tsx';

const Header = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(selectAuth);
    const { userId } = user;
    const [userData, setUserData] = useState({} as any);
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState('');
    const [nationalID, setNationalID] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [residentCountry, setResidentCountry] = useState('');
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);


    const toggleMobileMenu = () => {
        setMobileMenuVisible(!mobileMenuVisible);
    };

    useEffect(() => {
        const getProfileData = async () => {
            try {
                setLoading(true)
                const response = await UserService.getUserProfile(userId);
                const data = response.data;
                if (JSON.stringify(data) !== JSON.stringify(userData)) {
                    setUserData(data);
                    setFirstName(data?.firstName || '');
                    setLastName(data?.lastName || '');
                    setGender(data?.gender || '');
                    const formattedDate = formatDate(data?.dateOfBirth);
                    setDob(formattedDate);
                    setNationalID(data?.nationalID || '');
                    setPhoneNumber(data?.phoneNumber || '');
                    setAddress(data?.address || '');
                    setResidentCountry(data?.resident_country || '');
                }
            } catch (error) {
                toast.error('An Error occurred while fetching your profile, please try again later');
            } finally {
                setLoading(false);
            }
        };

        dispatch(setProfile(
            {
                lastName,
                firstName,
                gender,
                dateOfBirth: dob,
                nationalID,
                phoneNumber,
                address,
                resident_country: residentCountry,

            }
        ))

        getProfileData();
    }, [userId, userData, dispatch, lastName, firstName, gender, dob, nationalID, phoneNumber, address, residentCountry]);

    const formatDate = (inputDate: string | number | Date) => {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return (
        <>
            <Navbar color="light" light expand="md" className="navigationContainer">
                <NavbarBrand href="#" className="logo">
                    <img src={logo} alt="logo" />
                </NavbarBrand>

                {/* Hamburger icon for mobile menu */}
                <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                    <FaBars />
                </div>

                {mobileMenuVisible && (
                    <Nav className="mobile-menu" navbar>
                        <NavItem>
                            <Link to="/money-transfer" className="nav-link">
                                <FaMoneyBillWave className="icon" />
                                Transfer Funds
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/transactions" className="nav-link">
                                <FaExchangeAlt className="icon" />
                                Transactions
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/beneficiaries" className="nav-link">
                                <FaUserFriends className="icon" />
                                Beneficiaries
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/settings" className="nav-link">
                                <FaCog className="icon" />
                                Settings
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/help" className="nav-link">
                                <FaQuestionCircle className="icon" />
                                Help
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/signin" className="nav-link">
                                <FaUserCog className="icon" />
                                Logout
                            </Link>
                        </NavItem>
                    </Nav>
                )}

                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <Link to="/" className="nav-link">
                            <FaEnvelope className="icon" />
                            Notifications
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to="/profile" className="nav-link">
                            <FaUser className="icon" />
                            Welcome back, {firstName}
                        </Link>
                    </NavItem>
                </Nav>
            </Navbar>
        </>
    );
};


export default Header;
