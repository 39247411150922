import React, { useEffect, useState } from 'react';
import { Card, CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../styles/forgotPassword.css';
import { Helmet } from 'react-helmet-async';

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const [headingText, setHeadingText] = useState('');
    const fullHeadingText = "Forgot Your Password?";

    useEffect(() => {
        let index = 0;
        const intervalId = setInterval(() => {
            setHeadingText(fullHeadingText.slice(0, index));
            index++;
            if (index > fullHeadingText.length) {
                clearInterval(intervalId);
            }
        }, 100);
        return () => clearInterval(intervalId);
    }, []);


    const handleResetPassword = () => {
        console.log('Password reset requested for email:', email);
    };

    return (
        <div className="forgot-password-page">
            <Helmet>
                <title>Forgot Password - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <Card className="forgot-password-card">
                <CardBody>
                    <h4 className="forgot-password-heading">{headingText}</h4>
                    <p className="forgot-password-text">
                        Enter your email address below and we'll send you instructions on how to reset your password.
                    </p>
                    <Form>
                        <FormGroup>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className='inputField'
                            />
                        </FormGroup>
                        <div className="text-center mt-3">
                            <Button onClick={handleResetPassword} className="buttonSubmit" style={{ backgroundColor: "#27ca9f" }}>
                                Reset Password
                            </Button>
                        </div>
                        <div className="text-center mt-3">
                            <Link to='/signin' className="text-underline">
                                Back to Sign In
                            </Link>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
};

export default ForgotPassword;
