import React, { useEffect, useState } from 'react';
import { Card, CardBody, Spinner, Table } from 'reactstrap';
import Header from '../components/header.tsx';
import Footer from '../components/footer.tsx';
import '../styles/transaction-history.css';
import Sidebar from '../components/sidebar.tsx';
import { useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/auth.slice.ts';
import { toast } from 'react-toastify';
import AccountsService from '../api/AccountsService.tsx';
import { useNavigate } from 'react-router-dom'
import Pagination from '../components/pagination.tsx';
import { Helmet } from 'react-helmet-async';

const TransactionHistory = () => {
    const { user } = useSelector(selectAuth);
    const { userId } = user;
    const navigate = useNavigate();
    const [depositTransactions, setDepositTransactions] = useState([]);
    const [withdrawalTransactions, setWithdrawalTransactions] = useState([]);
    const [transferTransactions, setTransferTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const getUserDeposits = async () => {
            try {
                if (userId === undefined) {
                    navigate('/login');
                };
                setLoading(true);
                const response = await AccountsService.getUserDepositTransactions(userId);
                const deposits = response?.depositTransactions || [];
                setDepositTransactions(deposits);
            } catch (error) {
                setError(error.message);
                toast.error('An Error occurred while fetching your deposits, please try again later');
            } finally {
                setLoading(false);
            }
        };


        const getUserTransfers = async () => {
            try {
                const response = await AccountsService.getUserTransferTransactions(userId);
                const transfers = response?.transferTransactions || [];
                setTransferTransactions(transfers);
            } catch (error) {
                toast.error('An Error occurred while fetching your transfers, please try again later');
            }
        };



        const getUserWithdrawals = async () => {
            try {
                const response = await AccountsService.getUserWithdrawalTransactions(userId);
                const withdrawals = response?.withdrawalTransactions || [];
                setWithdrawalTransactions(withdrawals);;
            } catch (error) {
                toast.error('An Error occurred while fetching your withdrawals, please try again later');
            }
        };
        getUserDeposits();
        getUserTransfers();
        getUserWithdrawals();

    }, [navigate, userId]);

    const itemsPerPage = 10;
    const allTransactions = [...depositTransactions, ...transferTransactions, ...withdrawalTransactions];
    const totalPages = Math.ceil(allTransactions.length / itemsPerPage);

    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    const paginatedTransactions = allTransactions.slice(startIdx, endIdx);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const formatDate = (inputDate: string | number | Date) => {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    if (error) {
        return <div>{error}</div>
    }



    return (
        <>
            <Helmet>
                <title>Transactions - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <Header />
            <Sidebar />
            <div className="transaction-history-container">
                {loading ? (
                    <div className='spinner'>
                        <Spinner
                            color="primary"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="secondary"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="success"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="danger"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="warning"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="info"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="light"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="dark"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                    </div>
                ) : (
                    <Card className="transaction-history-card">
                        <CardBody>
                            <h4 className="heading">
                                <u>Transaction History</u>
                            </h4>
                            {allTransactions.length === 0 ? (
                                <p className="no-transactions">No transactions yet</p>
                            ) : (
                                <>
                                    <Table striped responsive>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Transaction Reference</th>
                                                <th>Description</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedTransactions?.map((transaction, index) => (
                                                <tr key={startIdx + index + 1}>
                                                    <td>{startIdx + index + 1}</td>
                                                    <td>{transaction.referenceNumber}</td>
                                                    <td>{transaction.description.toLowerCase()}</td>
                                                    <td>
                                                        {transaction.type === 'DEPOSIT' ? transaction.amount : -transaction.amount}
                                                    </td>
                                                    <td>{transaction.status.toLowerCase()}</td>
                                                    <td>{formatDate(transaction.created_at)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                    {/* Pagination */}
                                    <Pagination
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange}
                                    />
                                </>
                            )}
                        </CardBody>
                    </Card>
                )}
            </div>
            <Footer />
        </>
    );
};

export default TransactionHistory;
