// Beneficiaries.js
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, CardText, Button, Row, Col, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import Header from '../components/header.tsx';
import Footer from '../components/footer.tsx';
import '../styles/beneficiaries.css';
import Sidebar from '../components/sidebar.tsx';
import AccountService from '../api/AccountsService.tsx';
import { useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/auth.slice.ts';
import { ToastContainer, toast } from 'react-toastify';
import TextArea from 'antd/es/input/TextArea';
import AccountsService from '../api/AccountsService.tsx';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Beneficiaries = () => {
    const [beneficiaries, setBeneficiaries] = useState([

    ]);

    const [modalOpen, setModalOpen] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { user } = useSelector(selectAuth);
    const { userId } = user;
    const [beneficiaryName, setBeneficiaryName] = useState('');
    const [beneficiaryAccountNumber, setBeneficiaryAccountNumber] = useState('');
    const beneficiaryBank = 'INTERNAL';
    const [transferModalOpen, setTransferModalOpen] = useState(false);
    const [transferAmount, setTransferAmount] = useState('');
    const [transferReason, setTransferReason] = useState('');
    const navigate = useNavigate();
    const profile = useSelector((state: { profile: any; }) => state.profile);
    const { accountNumber } = profile;
    const [transferLoading, setTransferLoading] = useState(false);
    const [addBeneficiaryLoading, setAddBeneficiaryLoading] = useState(false);
    const [beneficiaryId, setBeneficiaryId] = useState('');

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const toggleTransferModal = () => {
        setTransferModalOpen(!transferModalOpen);
    };

    const handleTransactClick = async (beneficiaryAccountNumber: string) => {
        toggleTransferModal();
        setBeneficiaryId(beneficiaryAccountNumber)
    };

    const handleConfirmTransfer = async () => {
        toggleTransferModal();
        try {
            setTransferLoading(true);
            const payload = {
                amount: Number(transferAmount),
                description: transferReason,
                sourceAccountNumber: accountNumber,
                destinationAccountNumber: beneficiaryId,
            }
            const response = await AccountsService.sendMoney(payload);
            toast.success('Transaction Successful');
            navigate('/success', {
                state: {
                    beneficiaryAccountNumber: beneficiaryId,
                    transferAmount,
                    referenceNumber: response?.transaction?.referenceNumber,
                    newBalance: response?.transaction.newBalance,
                },
            });
        } catch (error) {
            setError(error.message);
            toast.error(error.message);
        } finally {
            setTransferLoading(false);
        }
    };



    const handleAddBeneficiary = async () => {
        try {
            setAddBeneficiaryLoading(true);
            const payload = {
                userId,
                name: beneficiaryName,
                accountNumber: beneficiaryAccountNumber,
                bank: beneficiaryBank,
            };
            await AccountService.createBeneficiary(payload);
            const updatedBeneficiaries = await AccountService.getBeneficiaries(userId);
            setBeneficiaries(updatedBeneficiaries.data);
            toggleModal();
        } catch (error) {
            setError(error.message);
            toast.error(error.message);
        } finally {
            setAddBeneficiaryLoading(false);
        }
    };

    const handleDeleteBeneficiary = async (beneficiaryId: any) => {
        try {
            setLoading(true);
            await AccountService.deleteBeneficiary({ userId, beneficiaryId });
            setLoading(false);

            const updatedBeneficiaries = await AccountService.getBeneficiaries(userId);
            setBeneficiaries(updatedBeneficiaries.data || []);
        } catch (error) {
            setError(error.message);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchBeneficiaries = async () => {
            try {
                setLoading(true);
                const beneficiariesData = await AccountService.getBeneficiaries(userId);
                setBeneficiaries(beneficiariesData?.data || []);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                toast.error(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBeneficiaries();
    }, [])

    return (
        <>
            <Helmet>
                <title>Beneficiaries - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <Header />
            <Sidebar />
            <div className="beneficiaries-container">
                <Row>
                    <Col md={6} sm={12} lg={6} className="mb-3">
                        <Card className="add-beneficiary-card" onClick={toggleModal}>
                            <CardBody>
                                <CardTitle tag="h5" className="text-center mb-3" style={{ color: '#27ca9f' }}>
                                    Add Beneficiary
                                </CardTitle>
                                <CardText className="text-center">
                                    Click to add a new beneficiary
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} sm={12} lg={6}>
                        {/* Empty column to create space between "Add Beneficiary" and the list */}
                    </Col>
                </Row>
                <Row>
                    {loading ? (<div className='spinner'>
                        <Spinner
                            color="primary"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="secondary"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="success"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="danger"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="warning"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="info"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="light"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        <Spinner
                            color="dark"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                    </div>) : (
                        <>
                            {beneficiaries.map((beneficiary: any) => (
                                <Col md={6} lg={6} sm={12} key={beneficiary.id}>
                                    <Card className="beneficiary-card">
                                        <CardBody>
                                            <CardTitle tag="h5">{beneficiary.name}</CardTitle>
                                            <CardText>
                                                <strong>Account Number:</strong> {beneficiary.accountNumber}<br />
                                                <strong>Bank:</strong> {beneficiary.bank}
                                            </CardText>
                                            {loading ? (<div className='spinner'>
                                                <Spinner
                                                    color="primary"
                                                    type="grow"
                                                >
                                                    Loading...
                                                </Spinner>
                                                <Spinner
                                                    color="secondary"
                                                    type="grow"
                                                >
                                                    Loading...
                                                </Spinner>
                                                <Spinner
                                                    color="success"
                                                    type="grow"
                                                >
                                                    Loading...
                                                </Spinner>
                                                <Spinner
                                                    color="danger"
                                                    type="grow"
                                                >
                                                    Loading...
                                                </Spinner>
                                                <Spinner
                                                    color="warning"
                                                    type="grow"
                                                >
                                                    Loading...
                                                </Spinner>
                                                <Spinner
                                                    color="info"
                                                    type="grow"
                                                >
                                                    Loading...
                                                </Spinner>
                                                <Spinner
                                                    color="light"
                                                    type="grow"
                                                >
                                                    Loading...
                                                </Spinner>
                                                <Spinner
                                                    color="dark"
                                                    type="grow"
                                                >
                                                    Loading...
                                                </Spinner>
                                            </div>) : (<>
                                                <Button color="danger" onClick={() => handleDeleteBeneficiary(beneficiary.accountNumber)}>
                                                    Delete
                                                </Button>
                                                <Button color="success" onClick={() => handleTransactClick(beneficiary.accountNumber)} className='transactButton'>
                                                    Transact
                                                </Button>
                                            </>)}
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </>
                    )}
                </Row>
                <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            </div>
            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Add Beneficiary</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                                type="text"
                                id="name"
                                value={beneficiaryName}
                                onChange={(e) => setBeneficiaryName(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="accountNumber">Account Number</Label>
                            <Input
                                type="text"
                                id="accountNumber"
                                value={beneficiaryAccountNumber}
                                onChange={(e) => setBeneficiaryAccountNumber(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="bank">Bank</Label>
                            <Input
                                type="text"
                                id="bank"
                                value={beneficiaryBank}
                                onChange={(e) => setBeneficiaryAccountNumber(e.target.value)}
                                required
                                readOnly
                            />
                        </FormGroup>
                        {addBeneficiaryLoading ? (
                            <div className='spinner'>
                                <Spinner
                                    color="primary"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="secondary"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="success"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="danger"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="warning"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="info"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="light"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="dark"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <Button onClick={handleAddBeneficiary} style={{ backgroundColor: '#27ca9f' }}>
                                Confirm Beneficiary
                            </Button>
                        )}

                    </Form>
                </ModalBody>
            </Modal>
            <Modal isOpen={transferModalOpen} toggle={toggleTransferModal}>
                <ModalHeader toggle={toggleTransferModal}>Transfer Funds</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="transferAmount">Transfer Amount</Label>
                            <Input
                                type="number"
                                id="transferAmount"
                                value={transferAmount}
                                onChange={(e) => setTransferAmount(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="transferReason">Transfer Reason</Label>
                            <TextArea
                                id="transferReason"
                                value={transferReason}
                                onChange={(e) => setTransferReason(e.target.value)}
                                required
                            />
                        </FormGroup>
                        {transferLoading ? (
                            <div className='spinner'>
                                <Spinner
                                    color="primary"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="secondary"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="success"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="danger"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="warning"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="info"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="light"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                                <Spinner
                                    color="dark"
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <Button onClick={handleConfirmTransfer} style={{ backgroundColor: '#27ca9f' }}>
                                Confirm Transfer
                            </Button>
                        )}
                    </Form>
                </ModalBody>
            </Modal>
            <Footer />
        </>
    );
};

export default Beneficiaries;
