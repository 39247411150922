import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ element, ...rest }) => {
    const { isAuthenticated } = useSelector((state: any) => state.auth);

    return isAuthenticated ? (
        <Outlet />
    ) : (
        <Navigate to="/signin" replace />
    );
};

export default PrivateRoute;
