import React, { useState } from 'react';
import '../styles/help-me.css'; // Import the CSS file
import Header from '../components/header.tsx';
import Sidebar from '../components/sidebar.tsx';
import Footer from '../components/footer.tsx';
import { Helmet } from 'react-helmet-async';

const HelpMe = () => {
    const [inquiry, setInquiry] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle the inquiry submission logic (e.g., send to server, show confirmation)
        console.log('Inquiry Submitted:', inquiry);
        // Reset the form after submission
        setInquiry('');
    };

    return (
        <>
            <Helmet>
                <title>Help Me - Mattress Bank</title>
                <meta name='description' content='' />
            </Helmet>
            <Header />
            <Sidebar />
            <div className="help-me-container">
                <h2>Help Me!</h2>
                <p>If you have any questions or need assistance, please submit your inquiry below:</p>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="inquiry">Your Inquiry:</label>
                    <textarea
                        id="inquiry"
                        name="inquiry"
                        value={inquiry}
                        onChange={(e) => setInquiry(e.target.value)}
                        placeholder="Type your inquiry here..."
                        required
                    />
                    <button className="submitHelp">Submit Inquiry</button>
                </form>
            </div>
            <Footer />
        </>

    );
};

export default HelpMe;
